<template>
  <div class="d-flex align-items-center">
    <div class="symbol symbol-45px me-5">
      <Img :src="user.photo_url"></Img>
    </div>
    <div class="d-flex justify-content-start flex-column">
      <router-link
          :to="/users/ + user.id + '/personal'"
          class="text-dark fw-bolder text-hover-primary fs-6"
      >{{ user.name }}</router-link>
      <a
          :href="mailTo"
          class="text-muted fw-bold text-muted d-block fs-7"
      >{{ user.email }}</a>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from "vue";
import Img from "@/components/general/Img";

export default defineComponent({
  name: "UserBox",
  components: {Img},
  props: {
    user: Object
  },
  computed: {
    mailTo() {
      return `mailto:${this.user.email}`;
    }
  }
});
</script>