<template>
  <div>
    <div @click="openShift()" class="row mb-5 pt-3">
      <div class="col-1 text-center">
        <div>
          <span
            class="fs-6 fw-bolder text-dark text-hover-primary cursor-pointer"
            ><i class="fas fa-eye"></i>
          </span>
        </div>
      </div>
      <div class="col-3">
        <div>
          <span
            class="fs-6 fw-bolder text-dark text-hover-primary cursor-pointer"
          >
            {{ shift.label }} /{{ shift.duration }} hrs/
          </span>
        </div>
      </div>
      <div class="col-3">
        <span class="fs-6 fw-bolder text-dark">{{ shift.description }}</span>
      </div>
      <div class="col-3 text-end">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-10 text-start">
            <div v-if="shift.contact_on_location">
              <span class="fs-6 fw-bolder text-dark">Contact on location:</span
              ><br /><br />
              <UserBox :user="shift.contact_on_location"></UserBox>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-5"></div>
          <div class="col-7">
            <div class="flex-fill">
              <div class="d-flex flex-column me-2 mt-1">
                <div class="d-flex flex-stack mb-2">
                  <span class="text-muted me-2 fs-7 fw-bold">
                    Crew : {{ shift.crew.total_booked }} /
                    {{ shift.crew.total }}
                  </span>
                </div>

                <div class="progress h-6px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :class="progressBarColor(shift.crew.progress)"
                    :style="`width: ${shift.crew.progress}%`"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import UserBox from "@/components/widgets/user/UserBox.vue";

export default defineComponent({
  name: "dashboard-job-shift-card",
  components: {UserBox},
  props: {
    index: Number,
    shift: Object
  },
  methods: {
    progressBarColor(progress) {
      if (progress <= 33) {
        return "bg-danger";
      } else if (progress > 33 && progress < 99) {
        return "bg-warning";
      }
      return "bg-success";
    },
    openShift() {
      return this.$router.push("/jobs/" + this.shift.job_id + "/shifts?shift=" + this.shift.id);
    }
  }
});
</script>

<style scoped>
.fa-eye {
  font-size: 1.5em;
}
</style>
