<template>
  <div>
    <div class="d-flex justify-content-center mt-3">
      <span
        @click="handleJobReasonEditState"
        v-if="!job.reason && !editMode && $can('job_reason_edit', 'all')"
        class="cursor-pointer"
        ><i class="text-gray-600 fw-bolder fa fa-edit"></i
      ></span>
      <div
        :contenteditable="$can('job_reason_edit', 'all')"
        v-if="job.reason && !editMode"
        @blur="handleChangeJobReason($event, job.id)"
        class="flex-fill bg-secondary rounded text-center reason-div text-dark fw-bolder p-1"
        :class="$can('job_reason_edit', 'all') ? 'cursor-pointer' : ''"
      >
        <span
          v-html="job.reason.replaceAll('\n', '<br>')"
        ></span>
      </div>
      <div
        contenteditable="true"
        ref="reason"
        v-if="editMode"
        class="flex-fill bg-secondary rounded text-center cursor-pointer reason-div text-dark fw-bolder p-1"
        @blur="handleChangeJobReason($event, job.id)"
      ></div>
    </div>
  </div>
</template>
<script lang="js">
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  name: "Job-Reason",
  props: {
    job: Object,
  },
  data() {
    return {
      editMode: false,
    }
  },
  methods: {
    ...mapActions("DashboardModule", ["changeJobReason"]),
    handleJobReasonEditState() {
      this.editMode = true;
      this.$nextTick(() => this.$refs.reason.focus());
    },
    handleChangeJobReason(event, id) {
      this.changeJobReason({id, reason: event.target.innerText.trim()});
      this.editMode = false;
    }
  }
});
</script>

<style scoped>
.reason-div {
  max-width: 14rem;
}
</style>
