<template>
  <div class="d-flex flex-column w-100 me-2">
    <div class="d-flex flex-stack mb-2">
      <span class="text-muted me-2 fs-8 fw-bold text-nowrap ms-auto">
        Crew : {{ bookedCrew }} / {{ totalcrew }}
      </span>
    </div>

    <div class="progress h-6px">
      <div
        class="progress-bar"
        role="progressbar"
        :class="progressBarColor"
        :style="`width: ${percentageOfCrewBooked}%`"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "booked-crew-progress-bar",
  props: {
    crew: Object,
    breakdown: Object
  },
  computed: {
      totalcrew() {
        if (this.breakdown !== undefined) {
          return this.breakdown.total;
        }

        return this.crew.map(shift => shift.total).reduce((a, b) => a + b, 0);
      },
      bookedCrew() {
        if (this.breakdown !== undefined) {
          return this.breakdown.total_booked;
        }
        return this.crew.map(shift => shift.total_booked).reduce((a, b) => a + b, 0)
      },
      percentageOfCrewBooked () {
         return Math.floor((this.bookedCrew / this.totalcrew) * 100)
      },
      progressBarColor() {
          if(this.percentageOfCrewBooked <= 33) {
              return "bg-danger"
          } else if(this.percentageOfCrewBooked > 33 && this.percentageOfCrewBooked < 99){
              return "bg-warning"
          }
          return "bg-success"
      }
  }
});
</script>
