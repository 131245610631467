<template>
  <!--begin::Dashboard-->
  <div class="row gy-5 g-xl-8">
    <JobsOverview
        widget-classes="card-xxl-stretch mb-5 mb-xl-8"
    ></JobsOverview>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import JobsOverview from "@/components/dashboard/JobsOverview.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    JobsOverview
  },
  mounted() {
    const isApplicant = this.$store.getters.currentUser.role[0].title == "Applicant" ? true : false
    if (!this.$can("dashboard_access", "all") && !isApplicant) {
      this.$router.push({path: "/my-dashboard"});
    } else if(isApplicant){
      this.$router.push("/users/me/personal");
    }else {
      this.$router.push({path: "/"});
    }
    setCurrentPageTitle("Dashboard");
  }
});
</script>
