<template>
  <ViewVenueModal :venue="currentlyViewedVenue"></ViewVenueModal>
  <DeleteModal
      @delete="handleDeleteJob"
      :currentlySelectedEntry="currentlySelectedJob.name"
  ></DeleteModal>
  <CancelJobDialog
    :jobData="selectedJobForCancel"
    @cancel="handleJobCancel"
  ></CancelJobDialog>
  <div class="card" :class="widgetClasses">
    <!--begin::Header-->
    <div class="row card-header border-0 pt-5 px-0 px-md-9">
      <div class="col-6">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">Jobs Overview</span>

          <span class="text-muted mt-1 fw-bold fs-7"
            >Overview of upcoming and past jobs</span
          >
        </h3>
      </div>

      <div class="col-6">
        <div class="row">
          <div class="col-12 col-xxl-8 d-flex justify-content-end align-items-center order-2 order-xxl-1">
            <div class="form-check form-switch mt-3 mt-xxl-0">
              <input
                  class="form-check-input"
                  type="checkbox"
                  id="showOwnCheckbox"
                  unchecked
                  v-model="query.own"
              />
              <label class="form-check-labe text-nowrap" for="showOwnCheckbox"
              >Show only my jobs</label
              >
            </div>
          </div>
          <div class="col-12 col-xxl-4 d-flex justify-content-end order-1 order-xxl-2">
            <div
              class="card-toolbar"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-trigger="hover"
              title="Click to add a new job"
              v-if="$can('job_create', 'all')"
            >
              <router-link
                to="/jobs/create"
                class="btn btn-sm btn-success ms-auto create-job-button"
              >
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="media/icons/duotone/Code/Plus.svg" />
                </span>
                Create New Job
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3 px-0 px-md-9">
      <div class="row">
        <div
          class="col col-md-4 col-12 d-flex justify-content-between justify-content-md-start mb-3 mb-md-0"
        >
          <button
            :class="jobsType == 'upcoming' ? 'btn-primary' : 'btn-secondary'"
            class="btn me-2 mb-2 flex-grow-1 flex-md-grow-0"
            @click="setJobsTypeFilter('upcoming')"
          >
            Upcoming Jobs
          </button>
          <button
            :class="jobsType == 'past' ? 'btn-primary' : 'btn-secondary'"
            class="btn mb-2 flex-grow-1 flex-md-grow-0 me-0"
            @click="setJobsTypeFilter('past')"
          >
            Past Jobs
          </button>
        </div>
        <div class="col-12 col-md mb-4 mb-md-0">
          <ClientDropdown @change="updateSearchByClient" v-if="$can('company_filter_access', 'all')"></ClientDropdown>
        </div>
        <div class="col-12 col-md mb-4 mb-md-0">
          <CountriesDropdown
            @change="updateSearchByCountry"
          ></CountriesDropdown>
        </div>
        <div class="col-12 col-md">
          <SearchField
            :placeholder="'Search Jobs'"
            @changeSearch="updateSearchByTextInput"
          ></SearchField>
        </div>
      </div>
      <div class="row mt-3" v-if="!loading">
        <div class="col-12 col-md-8 offset-md-4 d-flex justify-content-end">
            <el-checkbox-group class="d-flex flex-fill row justify-content-md-end" v-model="query.branches">
              <div                 v-for="branch in branches"
                                   :key="branch.id" class="col-6 col-md-4 col-xl-3 col-xxl-2">
              <el-checkbox
                :label="branch.id"
                >{{ branch.name }}</el-checkbox
              >
              </div>
            </el-checkbox-group>
        </div>
      </div>
      <!--begin::Loading spinner-->
      <LoadingSpinner v-if="loading"></LoadingSpinner>
      <!--end::Loading spinner-->

      <!--begin::Table container-->
      <div class="table-responsive" v-else>
        <!--begin::Table-->
        <table
          class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
        >
          <!--begin::Table head-->
          <thead>
            <tr class="fw-bolder text-muted">
              <th style="width: 15%">
                <div class="dropdown">
                  <span
                    class="fs-6 dropdown-toggle w-100 d-flex justify-content-between align-items-center"
                    id="job_status_button_dropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{
                      selectedStatusFilter == ""
                        ? "Status"
                        : selectedStatusFilter
                    }}
                  </span>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="job_status_button_dropdown"
                  >
                    <li>
                      <button
                        class="dropdown-item text-muted"
                        type="button"
                        @click="selectedStatusFilter = ''"
                      >
                        Clear
                      </button>
                    </li>
                    <li
                      v-for="(jobStatus, index) in jobStatusByTab"
                      :key="index"
                    >
                      <button
                        class="dropdown-item"
                        type="button"
                        @click="selectedStatusFilter = jobStatus"
                      >
                        {{ jobStatus }}
                      </button>
                    </li>
                  </ul>
                </div>
              </th>
              <th class="min-w-100px">When</th>
              <th class="min-w-100px">Invoice Ref</th>
              <th class="min-w-275px">Crew Handler</th>
              <th class="min-w-100px">Job</th>
              <th class="min-w-100px">Client</th>
              <th class="min-w-100px">Venue</th>
              <th class="min-w-100px text-end">Actions</th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody v-if="!loading">
            <!--begin::If search returns no results placeholder-->
            <td v-if="jobs == ''" colspan="8">
              <el-empty
                :description="
                  'OOPS... Unfortunately no jobs matching your filters were found.'
                "
              ></el-empty>
            </td>
            <!--end::If search returns no results placeholder--->
            <DashboardJobItem
              v-for="(job, index) in jobs"
              :key="index"
              :job="job"
              :jobFilter="jobsType"
              @openShifts="openShifts"
              @viewVenue="handleViewVenueModal"
              @openDeleteModal="openDeleteModal"
              @openCancelDialog="handleCancelDialog"
            ></DashboardJobItem>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
      <Pagination
        :currentPage="pagination.current_page"
        :totalPages="pagination.last_page"
        @changeCurrentPage="updateSearchBySelectedPage"
      ></Pagination>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import Pagination from "@/components/pagination/Pagination.vue";
import { mapActions, mapGetters } from "vuex";
import DashboardJobItem from "@/components/dashboard/DashboardJobItem.vue";
import ClientDropdown from "@/components/dashboard/DashboardClientsDropdown.vue";
import CountriesDropdown from "@/components/dashboard/DashboardCountriesDropdown.vue";
import SearchField from "@/components/general/SearchField.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import ViewVenueModal from "@/components/venues/modal/ViewVenueModal.vue";
import DeleteModal from "../general/DeleteModal";
import { Modal } from "bootstrap";
import { upcomingJobTypes, pastJobTypes } from "@/core/helpers/job_status.js"
import CancelJobDialog from "@/components/dashboard/Dialogs/CancelJobBookerDialog.vue";

export default defineComponent({
  name: "JobsOverview",
  components: {
    CountriesDropdown,
    ClientDropdown,
    DashboardJobItem,
    SearchField,
    Pagination,
    LoadingSpinner,
    ViewVenueModal,
    DeleteModal,
    CancelJobDialog
  },
  props: {
    widgetClasses: String
  },
  data() {
    return {
      query: {
        companyID: "",
        countryID: "",
        userSearchInput: "",
        branches: [],
        own: false,
        page: 1
      },
      jobsType: "upcoming", //Jobs type is upcoming by default !
      currentlyViewedVenue: null,
      currentlySelectedJob: {},
      pagination: {},
      selectedStatusFilter: "",
      branchesWatcherIsBlocked: false,
      selectedJobForCancel: null
    };
  },
  methods: {
    ...mapActions("DashboardModule", [
      "fetchData",
      "setPage",
      "setQuery",
      "setJobsType",
      "destroyData",
      "resetPageNumber",
      "resetState",
      "cancelJob"
    ]),
    updateSearchByClient(companyId) {
      this.query.branches = [];
      this.query.companyID = companyId;
      this.setQuery(this.query);
      this.refreshJobs();
    },
    updateSearchByCountry(countryId) {
      this.query.branches = [];
      this.query.countryID = countryId;
      this.setQuery(this.query);
      this.refreshJobs();
    },
    updateSearchByTextInput(textInput) {
      this.query.userSearchInput = textInput;
      this.setQuery(this.query);
      this.refreshJobs();
    },
    setJobsTypeFilter(value) {
      if (value == "upcoming") this.jobsType = "upcoming";
      else this.jobsType = "past";
      this.selectedStatusFilter = "";
      this.query.page = 1;
      this.setJobsType(this.jobsType);
      this.resetPageNumber();
      this.refreshJobs();
    },
    updateSearchBySelectedPage(selectedPageNumber) {
      this.query.page = selectedPageNumber
      this.refreshJobs();
    },
    openShifts(jobID) {
      this.fetchShiftsByJobID(jobID);
    },
    handleViewVenueModal(data) {
      this.currentlyViewedVenue = data;
    },
    handleCancelDialog(data) {
      this.selectedJobForCancel = data;
    },
    clearCancelDialogState() {
      this.selectedJobForCancel = null;
    },
    handleJobCancel() {
      this.cancelJob(this.selectedJobForCancel);
      this.clearCancelDialogState();
    },
    openDeleteModal(job) {
      this.currentlySelectedJob = job;
    },
    handleDeleteJob() {
      this.destroyData(this.currentlySelectedJob.id);
    },
    setParametersFilter(){
      const query = {};
      this.setPage(this.query.page);
      this.addQuery(query, 'page', this.query.page);
      this.setJobsType(this.jobsType);
      if (this.jobsType == 'upcoming') {
        this.addQuery(query, 'upcoming', 1);
      } else {
        this.addQuery(query, 'past', 1);
      }
      this.addQuery(query, 'company_id', this.query.companyID);
      this.addQuery(query, 'country_id', this.query.countryID);
      this.addQuery(query, 'search', this.query.userSearchInput);
      this.addQuery(query, 'show_own_jobs', this.query.own);
      if (this.query.branches.length > 0) {
        this.addQuery(query, 'branches', this.query.branches);
      }
      this.$router.replace({
        name: 'dashboard',
        query: query
      });
    },
    addQuery(query, nameParameter, parameter){
      if (parameter !== undefined && parameter !== '' && parameter != null){
        if (Array.isArray(parameter)){
          parameter = parameter.join(',');
        }
        query[nameParameter] = parameter;
      }
    },
    setBranchesFilterFromUrl(url){
      const branches = decodeURI(url);
      const branchesArray = branches.split(',');
      if (branchesArray.length > 0){
        this.query.branches = [];
        for(let i = 0; i < branchesArray.length; i++){
          this.query.branches.push(Number(branchesArray[i]));
        }
      }
    },
    setFilterParametersFromUrl(){
      this.branchesWatcherIsBlocked = true;
      const query = this.$route.query;
      if (query.page !== undefined) {
        this.query.page = Number(query.page);
        this.setPage(Number(query.page));
      }
      if (query.upcoming !== undefined){
        this.jobsType = "upcoming"
        this.setJobsType("upcoming");
      } else if (query.past !== undefined) {
        this.jobsType = "past"
        this.setJobsType("past");
      }
      if (query.branches !== undefined){
        this.setBranchesFilterFromUrl(query.branches);
      }
      if (query.company_id !== undefined){
        this.query.companyID = Number(query.company_id);
      }
      if (query.country_id !== undefined){
        this.query.countryID = Number(query.country_id);
      }
      if (query.search !== undefined){
        this.query.userSearchInput = query.search;
      }
      if (query.show_own_jobs !== undefined){
        this.query.own = query.show_own_jobs;
      }
      this.setQuery(this.query);
      this.fetchData({status: this.selectedStatusFilter});
      this.branchesWatcherIsBlocked = false;
    },
    refreshJobs() {
      this.setParametersFilter();
      this.fetchData({status: this.selectedStatusFilter});
    }
  },
  computed: {
    ...mapGetters("DashboardModule", ["jobs", "loading", "getPaginationData"]),
    ...mapGetters("ShiftsModule", ["getShifts", "loadingShifts"]),
    ...mapGetters("ListsModule", ["branches"]),
    jobStatusByTab() {
      if(this.jobsType == "upcoming") {
        return upcomingJobTypes;//.filter(a => a !== this.selectedStatusFilter);
      }else {
        return pastJobTypes;//.filter(a => a !== this.selectedStatusFilter);
      }
    }
  },
  created() {
    this.setFilterParametersFromUrl();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  },
  watch: {
    jobs() {
      this.resetPageNumber();
    },
    selectedStatusFilter() {
      this.fetchData({status: this.selectedStatusFilter});
    },
    "query.branches": {
      deep: true,
      handler() {
        if (!this.branchesWatcherIsBlocked) {
          this.refreshJobs();
        }
      }
    },
    "query.own"() {
      this.setQuery(this.query);
      this.refreshJobs();
    }
  }
});
</script>

<style scoped>
.create-job-button {
  white-space: nowrap;
}
</style>
