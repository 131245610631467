<template>
  <el-select
    v-model="value"
    filterable
    size="large"
    clearable
    placeholder="Select a client"
    class="w-100"
  >
    <el-option
      v-for="item in relatedClients"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "dasbhoard-client-dropdown",
  data() {
    return {
      value: "",
      companyID: null
    };
  },
  mounted() {
    if (this.$route.query.company_id)
      this.companyID = this.$route.query.company_id;
  },
  watch: {
    relatedClients() {
      if (this.companyID) {
        this.value = this.relatedClients.find(i => i.id == this.companyID).id;
        this.companyID = null;
      }
    }
  },
  computed: {
    ...mapGetters("DashboardModule", ["relatedClients"])
  }
});
</script>
