<template>
  <el-select
    v-model="value"
    filterable
    size="large"
    clearable
    placeholder="Country"
    class="w-100"
  >
    <el-option
      v-for="item in relatedCountries"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "dashboard-countries-dropdown",
  props: {
    defaultValue: [Number, String]
  },
  data() {
    return {
      value: "",
      countryID: null
    };
  },
  mounted() {
    if (this.$route.query.country_id) this.countryID = this.$route.query.country_id;
    if (this.defaultValue) this.value = this.defaultValue;
  },
  watch: {
    relatedCountries() {
      if (this.countryID) {
        this.value = this.relatedCountries.find(i => i.id == this.countryID).id;
        this.countryID = null;
      }
    }
  },
  computed: {
    ...mapGetters("DashboardModule", ["relatedCountries"])
  }
});
</script>
