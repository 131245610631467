<template>
  <tr class="accordion" id="kt_accordion_1">
    <td>
      <JobStatusButton
        :job="job"
        :jobsStatusFilter="jobFilter"
      ></JobStatusButton>
      <JobReason :job="job"></JobReason>
    </td>
    <td>
      <router-link
        :to="/jobs/ + job.id + '/shifts'"
        class="text-dark fw-bolder text-hover-primary d-block fs-6"
        >{{ job.closes_shift_time }}</router-link
      >
    </td>
    <td>
      <router-link
        :to="/jobs/ + job.id + '/shifts'"
        class="text-dark fw-bolder text-hover-primary d-block fs-6"
        >{{ job.invoice_reference }}</router-link
      >
    </td>
    <td>
      <UserBox v-if="job.project_manager" :user="job.project_manager"></UserBox>
      <p v-else class="text-danger fw-bolder text-hover-primary d-block fs-6">
        No CH
      </p>
    </td>
    <td>
      <router-link
        :to="/jobs/ + job.id + '/shifts'"
        class="text-dark fw-bolder text-hover-primary d-block fs-6"
        >{{ job.name }}</router-link
      >
    </td>

    <td>
      <router-link
        :to="/client/ + job.company.id"
        class="text-dark fw-bolder text-hover-primary d-block fs-6"
      >
        {{ job.company.name }}</router-link
      >
    </td>

    <td>
      <a
        v-if="job.venue.name"
        class="text-dark fw-bolder text-hover-primary d-block fs-6 cursor-pointer"
        data-bs-target="#viewVenueModal"
        data-bs-toggle="modal"
        @click="$emit('viewVenue', job.venue)"
        >{{ job.venue.name }}</a
      >
    </td>

    <td class="text-end">
      <button
        @click="$emit('openDeleteModal', job)"
        v-if="job.status == 'Cancelled' && $can('job_delete', 'all')"
        class="btn btn-danger btn-sm w-100 mb-2"
        data-bs-toggle="modal"
        data-bs-target="#deleteModal"
      >
        Delete
      </button>
      <router-link :to="`/jobs/${job.id}/book`" v-else-if="$can('can_book_worker', 'all') && !$can('can_request_worker', 'all')" class="btn btn-success btn-sm w-100 mb-2">
        Book
      </router-link>
      <button
        @click="$emit('openCancelDialog', { name: job.name, id: job.id })"
        :disabled="isAwaitingCancel.includes(job.id)"
        v-if="
          $can('can_cancel_own_company_jobs', 'all') &&
            !job.is_past &&
            getTotalCrew == 0 &&
            job.status !== 'Cancelled'
        "
        class="btn btn-warning btn-sm w-100 mb-2"
        data-bs-target="#cancelJobDialog"
        data-bs-toggle="modal"
      >
        <span
          v-if="isAwaitingCancel.includes(job.id)"
          class="spinner-border spinner-border-sm me-2"
          role="status"
        ></span>
        Cancel
      </button>
      <button
        class="btn btn-sm btn-secondary w-100 mb-2"
        :disabled="loading"
        @click="openShifts(job.id)"
      >
        <div
          v-show="loading"
          class="spinner-border spinner-border-sm me-2"
          role="status"
        ></div>
        {{ indexesOfJobsWithToggledShifts.includes(job.id) ? "Hide" : "View" }}
        {{ totalShifts }} {{ totalShifts == 1 ? "Shift" : "Shifts" }}
      </button>
      <BookedCrewProgressBard :crew="job.crew"></BookedCrewProgressBard>
    </td>
  </tr>
  <tr
    :class="{
      hiddenBorderBottom: !indexesOfJobsWithToggledShifts.includes(job.id)
    }"
    v-for="(shift, shiftIndex) in getShifts[job.id]"
    :key="shiftIndex"
  >
    <td colspan="12" class="p-0 m-0">
      <div :id="`collapse_${shift.id}`" class="collapse in bg-hover-light px-3">
        <div class="row">
          <div class="col-12">
            <DashboardJobShiftCard
              :shift="shift"
              :index="shiftIndex"
            ></DashboardJobShiftCard>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script lang="js">
import { defineComponent } from "vue";
import DashboardJobShiftCard from "@/components/dashboard/DashboardJobShiftCard.vue";
import UserBox from "@/components/widgets/user/UserBox.vue";
import JobStatusButton from "@/components/dashboard/JobStatusButton.vue";
import JobReason from "@/components/dashboard/JobReason.vue";
import BookedCrewProgressBard from "@/components/dashboard/BookedCrewProgressBar.vue";
import {mapActions, mapGetters} from "vuex";
import {Collapse} from "bootstrap";

export default defineComponent({
  name: "DashboardJobItem",
  components: {DashboardJobShiftCard, UserBox, JobStatusButton, JobReason, BookedCrewProgressBard},
  props: {
    job: {
      required: true,
      type: Object
    },
    jobFilter: {
      type: String
    }
  },
  data() {
    return {
      indexesOfJobsWithToggledShifts: [],
      loading: false
    };
  },
  methods: {
    ...mapActions("ShiftsModule", ["fetchShiftsByJobID"]),
    openShifts(jobId) {
      this.loading = true;
      this.fetchShiftsByJobID(jobId)
        .then(() => {
          // The problem: Above in the template, we iterate dynamically through job shifts where each shift gets passed its own id as id for the
          // collapsible element, however the toggling logic is called OUTSIDE of the v-for iteration, hence we have to find a way to get the ids
          // of the job shifts.
          // My approach: I get the shift IDs from the store getter with the correct job ID in this then() block. That way I can use the array of
          // shift IDs to mirror each collapsible element with the correct ID, resulting in a for loop that's responsible to expand/collapse
          // all fetched shifts for the selected job row.
          const shiftIds = this.getShifts[jobId].map(s => s.id);
          for (let i = 0; i < shiftIds.length; i++) {
            const jobShiftsToCollapse = document.getElementById(`collapse_${shiftIds[i]}`);
            const collapse = new Collapse(jobShiftsToCollapse, {
              toggle: true
            })
          }
          this.formatViewShiftsButtonText(jobId);
          this.loading = false;
        });
    },
    formatViewShiftsButtonText(jobId) {
      const index = this.indexesOfJobsWithToggledShifts.indexOf(jobId);
      if (index > -1){
        this.indexesOfJobsWithToggledShifts.splice(index, 1);
      } else {
        this.indexesOfJobsWithToggledShifts.push(jobId);
      }
    }
  },
  computed: {
    ...mapGetters("ShiftsModule", ["getShifts"]),
    ...mapGetters("DashboardModule", ["isAwaitingCancel"]),
    totalShifts() {
      if (this.job.crew) {
        return this.job.crew.length;
      } else return "x";
    },
    getTotalCrew() {
      return this.job.crew.map(shift => shift.total_booked).reduce((a, b) => a + b, 0)
    }
  }
});
</script>

<style scoped>
/* We use this class to prevent bottom borders from overlapping in an ugly way when job shifts are closed
(since we are toggling all shifts at once, rather than just one element like in other components) */
.hiddenBorderBottom {
  border-bottom: none !important;
}
</style>
