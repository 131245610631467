
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "cancel-job-booker-dialog",
  props: {
    jobData: {
      type: Object as PropType<object | null>,
      required: true
    }
  }
});
